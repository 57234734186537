
import { defineComponent } from "vue-demi";
import Partner from "@/components/owner/partner/index.vue";

export default defineComponent({
  name: "OwnerPartnerView",
  components: {
    Partner,
  },
});
